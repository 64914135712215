/*
Theme Name: Sushikiriz
Theme URI: https://bravad.ca
Author: Sébastien Gagné
Author URI: https://bravad.ca
Description: Wordpress bravad theme (Sushikiriz)
Version: 2.1.9
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: bravad
*/